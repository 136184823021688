import React from 'react';

import { Box, Palette, Text, HR, Flex } from '@updater/ui-uds';
import { useTheme } from '@emotion/react';
import { useSessionStorage } from '../../hooks';
import { providerAppSessionStorageKey } from '../../constants';

export const OrderReceipt: React.FC = () => {
  const theme = useTheme();
  const [sessionStorageValue] = useSessionStorage(
    providerAppSessionStorageKey,
    {}
  );

  return (
    <>
      <Box
        backgroundColor={Palette.gray96 as any}
        borderRadius="xs"
        overflow="hidden"
        width={['100%', null, null, null, '476px']}
        maxWidth="708px"
        marginBottom="m"
      >
        <Box backgroundColor={Palette.green as any} height="8px" width="100%" />
        <Box ml="xxxs" px="m" pt="m" pb="xs">
          <Text variant="lBold" mb="s">
            Order Receipt for your services
          </Text>
          <HR />
          <Flex justifyContent="space-between">
            <Box marginTop="s" marginBottom="xxs">
              Confirmation Number
            </Box>
            <Box marginTop="s" marginBottom="xxs">
              {sessionStorageValue?.confirmationNumber && (
                <Text
                  variant="mBold"
                  data-cy="order-confirmation-number"
                  data-testid="order-confirmation-number"
                >
                  {sessionStorageValue?.confirmationNumber}
                </Text>
              )}
            </Box>
          </Flex>
          <Flex justifyContent="space-between">
            <Box marginTop="s" marginBottom="xxs">
              Customer Name
            </Box>
            <Box marginTop="s" marginBottom="xxs">
              {sessionStorageValue?.customerInformation?.firstName && (
                <Text variant="mBold">
                  {sessionStorageValue?.customerInformation?.firstName}{' '}
                  {sessionStorageValue?.customerInformation?.lastName}
                </Text>
              )}
            </Box>
          </Flex>
          <Flex justifyContent="space-between">
            <Box marginTop="s" marginBottom="xxs">
              Order Date
            </Box>
            <Box marginTop="s" marginBottom="xxs">
              {sessionStorageValue?.orderDate && (
                <Text variant="mBold">{sessionStorageValue?.orderDate}</Text>
              )}
            </Box>
          </Flex>
          <Flex justifyContent="space-between">
            <Box marginTop="s" marginBottom="xxs">
              Service Address
            </Box>
            <Box marginTop="s" marginBottom="xxs">
              {sessionStorageValue?.customerInformation?.address && (
                <Text variant="mBold" textAlign="right">
                  {sessionStorageValue?.customerInformation?.address?.street}
                  <br />
                  {sessionStorageValue?.customerInformation?.address?.city}{' '}
                  {sessionStorageValue?.customerInformation?.address?.state}{' '}
                  {
                    sessionStorageValue?.customerInformation?.address
                      ?.postalCode
                  }
                </Text>
              )}
            </Box>
          </Flex>
          <Flex justifyContent="space-between">
            <Box marginTop="s" marginBottom="m">
              Phone Number
            </Box>
            <Box marginTop="s" marginBottom="m">
              {sessionStorageValue?.customerInformation?.phone && (
                <Text variant="mBold">
                  {sessionStorageValue?.customerInformation?.phone}
                </Text>
              )}
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
