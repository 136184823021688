import { LayoutSchema } from '@updater/ui-informant';

export const layoutSchema: LayoutSchema = {
  basePath: '/provider/quiz/',
  screens: [
    {
      name: 'services',
      route: 'services',
      heading: 'What services would you like?',
      subHeading: 'Select all that apply.',
      nextButtonText: 'Continue',
      fields: ['serviceLines'],
    },
    {
      name: 'devices',
      route: 'devices',
      heading: 'How many devices connect to your network at a time?',
      subHeading:
        'Think smartphones, computers, tablets, smart speakers, thermostats, etc.',
      nextButtonText: 'Continue',
      fields: ['devices'],
    },
    {
      name: 'activities',
      route: 'activities',
      subHeading: 'Select all that apply.',
      heading: 'What do you use the internet for?',
      nextButtonText: 'Continue',
      fields: ['activities'],
    },
    {
      name: 'tv',
      route: 'tv',
      subHeading: 'Select all that apply.',
      heading: 'What is important to you when it comes to TV?',
      nextButtonText: 'Continue',
      fields: ['tv'],
    },
    {
      name: 'phone',
      route: 'phone',
      heading: 'What do you use your home phone for?',
      subHeading: 'Select all that apply.',
      nextButtonText: 'Continue',
      fields: ['homePhone'],
    },
  ],
};
