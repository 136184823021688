import styled from '@emotion/styled';
import { Headset, IconContext, MoneyWavy, Smiley } from '@phosphor-icons/react';
import {
  Box,
  Flex,
  Image,
  Text,
  TextLink,
  theme,
  useTheme,
} from '@updater/ui-uds';
import { BorderRadiusKey } from '@updater/ui-uds/dist/es/types/theme-types';
import {
  AddressTypeaheadProvider,
  AddressWidget,
  type AddressWithNormalizaion,
} from '@updater/ui-widgets';
import { Formik } from 'formik';
import { useQual, useStore } from 'hooks';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { Events, useEnvironment, usePageEvent, useTracking } from 'services';
import { ResponsiveValue, Theme } from 'styled-system';
import type { Address, MicrositeCustomTheme, MicrositeTheme } from 'types';
import { useShallow } from 'zustand/react/shallow';

const ValueListItem = styled(Box)`
  height: 144px;
  padding: ${({ theme }) => `${theme.space.s}px`};
  background-color: ${({ theme }) => theme.customTheme.baseColors.cta}1F;
  border-radius: ${({ theme }) => theme.button.borderRadii.m};
  svg {
    margin: 0 auto;
    display: block;
  }
`;

const CtaSection: React.FC<{
  onSubmit: (address: AddressWithNormalizaion) => void;
}> = ({ onSubmit }) => {
  const { googlePlacesAPIKey } = useEnvironment();
  return (
    <Flex
      width="100%"
      maxWidth="620px"
      marginTop="none"
      marginBottom="l"
      marginX="auto"
      flexDirection="column"
      gap={`${theme.space.s}px`}
    >
      <AddressTypeaheadProvider
        value={useMemo(
          () => ({
            GOOGLE_PLACES_API_KEY: googlePlacesAPIKey,
          }),
          [googlePlacesAPIKey]
        )}
      >
        <Formik initialValues={{ address: {} }} onSubmit={() => {}}>
          <AddressWidget
            name="moveAddress"
            onComplete={onSubmit}
            continueButtonText="See all plans"
            allowMilitary={false}
            allowCommercial={false}
            allowInvalid={false}
            allowInvalidAddressSubmission={false}
            verifyAddressDescriptionText="You must use a valid address. We recommend using the suggested address below."
            militaryErrorMsg="You cannot use a military address. If you believe this is a mistake, email help@updater.com"
            commercialErrorMsg="You cannot use a commercial address. If you believe this is a mistake, email help@updater.com"
          />
        </Formik>
      </AddressTypeaheadProvider>

      {/* <Button onClick={onClick} size="l" name="plans" variant="primary">
        See all plans
      </Button> */}
      <Text variant="xs" mt="s" textAlign="center">
        By continuing, you agree to the{' '}
        <TextLink
          data-testid="termsAndPrivacyLink"
          showTextDecoration
          href="https://updater.com/uhs-terms/"
        >
          Updater terms of service and privacy policy
        </TextLink>
        .
      </Text>
    </Flex>
  );
};

/**
 * Figma - https://www.figma.com/design/RbtAFyx87eAxS2oggbT0ZD/Powered-By-Designs-2025?node-id=5-6266&m=dev
 */
export function RateLanding() {
  const router = useRouter();
  const tracking = useTracking();
  const { userFirstName, userLastName, zip, city, state, apartment, street } =
    useQual();
  const [setAddress] = useStore(useShallow((store) => [store.setAddress]));
  const [setAcceptedUpdaterTerms] = useStore(
    useShallow((store) => [store.setAcceptedUpdaterTerms])
  );
  usePageEvent(Events.RATE_LANDING);
  const theme = useTheme();

  //   TODO: we need to fix the type casting
  const { customTheme } = theme as unknown as MicrositeCustomTheme &
    MicrositeTheme;

  const setAddressAndRouteToQuiz = useCallback(
    (address: Address) => {
      setAddress(address);
      const details = {
        user_address: address,
        first_name: userFirstName,
        last_name: userLastName,
      };
      setAcceptedUpdaterTerms(true);
      tracking.trackEvent(
        Events.PRIVACY_AND_TERMS_CONSENT,
        'accepted',
        details
      );
      router.push('/quiz');
    },
    [
      router,
      setAcceptedUpdaterTerms,
      setAddress,
      tracking,
      userFirstName,
      userLastName,
    ]
  );

  return (
    <Box width="100%" maxWidth="940px" marginY="l" marginX="auto">
      <Box
        width="100%"
        maxWidth="620px"
        marginY="none"
        marginX="auto"
        borderBottom="1px solid ${Palette.gray80}"
      >
        <Box
          width="100%"
          height={['200px', '300px', '300px', '300px', '300px']}
          backgroundColor="softBlue"
          // 12px is missing as an option in standard borderRadii
          borderRadius={
            theme.button.borderRadii.m as ResponsiveValue<
              BorderRadiusKey,
              Theme
            >
          }
        >
          <Image
            borderRadius={
              theme.button.borderRadii.m as ResponsiveValue<
                BorderRadiusKey,
                Theme
              >
            }
            // width={['231px', '231px', '231px', '277px', '277px']}
            width="100%"
            height="100%"
            marginY="none"
            marginX="auto"
            display="block"
            objectFit="cover"
            src="https://res.cloudinary.com/updater-staging/image/upload/f_auto,q_auto:best,dpr_auto,fl_progressive,h_900/tv-internet/family_hero"
            alt="A minimalist illustration showing a TV, a laptop, and a router."
          />
        </Box>
        <Text
          as="h1"
          variant={['xlBold', 'xlBold', 'xxlBold', 'xxlBold', 'xxlBold']}
          marginY="s"
          textAlign="center"
        >
          Get connected with Rate
        </Text>
        <Text
          as="p"
          variant={['s', 'm', 'l', 'l', 'l']}
          textAlign="center"
          mb="xs"
        >
          Enter your new home address to compare the best plans for your home.
        </Text>
        <CtaSection onSubmit={setAddressAndRouteToQuiz} />
      </Box>

      <Box
        maxWidth={['100%', '620px', '620px', '940px', '940px']}
        marginX="auto"
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="border"
        py="2.25rem"
      >
        <Text as="h2" variant="xl" textAlign="center">
          Wherever you live, we've got you covered
        </Text>

        <Box
          marginTop="m"
          mx="auto"
          maxWidth={['100%', '300px', '450px', '450px', '450px']}
        >
          <Flex justifyContent="space-between">
            <Image
              height={['2rem', '2rem', '2rem', '2.5rem', '2.5rem']}
              src="https://res.cloudinary.com/updater-staging/image/upload/f_auto,q_auto,dpr_auto,fl_progressive,h_40/tv-internet/provider-logos/full-color/xfinity"
              alt="xfinity logo"
            />
            <Image
              height={['2rem', '2rem', '2rem', '2.5rem', '2.5rem']}
              src="https://res.cloudinary.com/updater-staging/image/upload/f_auto,q_auto,dpr_auto,fl_progressive,h_40/tv-internet/provider-logos/full-color/att"
              alt="att logo"
            />
            <Image
              height={['2rem', '2rem', '2rem', '2.5rem', '2.5rem']}
              src="https://res.cloudinary.com/updater-staging/image/upload/f_auto,q_auto,dpr_auto,fl_progressive,h_40/tv-internet/provider-logos/full-color/cox"
              alt="cox logo"
            />
          </Flex>
          <Flex justifyContent="space-around" mt="1rem">
            <Image
              height={['2rem', '2rem', '2rem', '2.5rem', '2.5rem']}
              src="https://res.cloudinary.com/updater-staging/image/upload/f_auto,q_auto,dpr_auto,fl_progressive,h_40/tv-internet/provider-logos/full-color/spectrum"
              alt="spectrum logo"
            />
            <Flex
              height={['2rem', '2rem', '2rem', '2.5rem', '2.5rem']}
              width="5rem"
            >
              <Text as="span" variant={['m', 'm', 'l', 'l', 'l']} my="auto">
                + more
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Box>

      <Box
        mt="2.25rem"
        maxWidth={['100%', '620px', '620px', '940px', '940px']}
        marginX="auto"
      >
        <Text as="h2" variant="xl" textAlign="center">
          Why shop with Rate?
        </Text>
        <Flex
          marginTop="m"
          width="100%"
          gap={`${theme.space.s}px`}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <IconContext.Provider
            value={{
              color: customTheme?.baseColors.cta,
              size: '32px',
              weight: 'light',
            }}
          >
            <ValueListItem width={['100%', '100%', '100%', '302px', '302px']}>
              <Smiley />
              <Text as="h3" variant="mBold" marginY="xxs" textAlign="center">
                Save time & sanity
              </Text>
              <Text as="p" variant="s" marginY="xxs" textAlign="center">
                Compare internet plans like flights - all at once.
              </Text>
            </ValueListItem>
            <ValueListItem width={['100%', '100%', '100%', '302px', '302px']}>
              <MoneyWavy />
              <Text as="h3" variant="mBold" marginY="xxs" textAlign="center">
                We search - you save
              </Text>
              <Text as="p" variant="s" marginY="xxs" textAlign="center">
                No added fees or markups, ever.
              </Text>
            </ValueListItem>
            <ValueListItem width={['100%', '100%', '100%', '302px', '302px']}>
              <Headset />
              <Text as="h3" variant="mBold" marginY="xxs" textAlign="center">
                Get live advice from a real human
              </Text>
              <Text as="p" variant="s" marginY="xxs" textAlign="center">
                Instant support from our team of expert concierges.
              </Text>
            </ValueListItem>
          </IconContext.Provider>
        </Flex>
      </Box>
    </Box>
  );
}
