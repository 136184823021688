import styled from '@emotion/styled';
import { Box, Text, Palette } from '@updater/ui-uds';
import { CallRailPhone } from 'components';
import { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { usePhoneNumber } from 'hooks';
import { Content } from './Content';
import { OrderSummary } from '../OrderSummary';
import { OrderReceipt } from '../OrderReceipt';
import { useTheme } from '@emotion/react';

const BoxBackground = styled(Box)`
  background: ${Palette.blue10};
`;

const ConfirmationText = styled(Text)`
  font-size: 12px;
`;

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.space.xxl}px;
  padding-bottom: ${({ theme }) => theme.space.xxl}px;
  width: 100%;
`;

const DefaultConfirmation = () => {
  const phoneNumber = usePhoneNumber({ isLeadMode: true });

  const { trackEvent } = useTracking({
    details: {
      phoneNumber,
    },
  });

  useEffect(() => {
    trackEvent({
      object: 'lead_mode_confirmation',
      verb: 'viewed',
    });
  }, []);

  return (
    <Wrapper>
      <Content>
        <Box maxWidth="648px" marginBottom="m">
          <Text as="h1" variant={['xxl', null, null, null, 'xxxl']}>
            One Last Step!
          </Text>
          <Text variant="l" marginTop="s" marginBottom="m">
            Your order is almost complete. Confirm your order by scheduling your
            installation by phone in less than 5 minutes. The sooner you do it,
            the faster your service will be installed!
          </Text>
          <Text variant="l" marginTop="m" marginBottom="s">
            Call now to speak with an agent directly!
          </Text>
          <BoxBackground
            padding="s"
            border={`2px solid ${Palette.blue30}`}
            borderRadius="xs"
            marginY="l"
          >
            <Text variant="m">
              Don&apos;t forget to have your confirmation and phone number handy
              when you speak with the agent to schedule your installation for
              service.
            </Text>
          </BoxBackground>
          <Box minWidth="324px" maxWidth={['auto', null, null, null, '324px']}>
            <CallRailPhone text="Call ###" variant="xxlBold" isLeadMode />
          </Box>
        </Box>
        <Box maxWidth={['100%', null, null, null, '476px']}>
          <OrderSummary />
          <OrderReceipt />
        </Box>
      </Content>
    </Wrapper>
  );
};

export function Confirmation() {
  // TODO: make custom confirmation components available here
  // const provider = useContext(MicrositeContext);
  return <DefaultConfirmation />;
}
