import React, { useContext } from 'react';

import { Box, Palette, Text, HR, Flex } from '@updater/ui-uds';
import { MicrositeContext } from 'context/MicrositeContext';
import { ShopType } from 'types';
import { Price } from '../Price';
import { useSessionStorage } from '../../hooks';
import { providerAppSessionStorageKey } from '../../constants';
import { showPricingDescription } from 'config/providers/helpers';

export const OrderSummary: React.FC = () => {
  const { shopType } = useContext(MicrositeContext);

  const [sessionStorageValue] = useSessionStorage(
    providerAppSessionStorageKey,
    {}
  );

  const { shortTermPrice, longTermPrice, providerName } =
    sessionStorageValue?.offer || {};
  const displayPrice = shortTermPrice ?? longTermPrice;

  const showDisclaimer = showPricingDescription({
    providerCode: sessionStorageValue?.offer?.providerCode,
    pricingDescription: sessionStorageValue?.offer?.pricingDescription,
  });

  return (
    <Box
      backgroundColor={Palette.gray96 as any}
      borderRadius="xs"
      overflow="hidden"
      width={['100%', null, null, null, '476px']}
      maxWidth="708px"
      marginBottom="m"
    >
      <Box backgroundColor={Palette.green as any} height="8px" width="100%" />
      <Box ml="xxxs" px="m" pt="m" pb="xs">
        <Text variant="lBold" mb="s" data-testid="order-summary-title">
          Your Shopping Cart
        </Text>
        <HR />
        <Flex justifyContent="space-between">
          <Box my="s" data-testid="order-summary-plan">
            {/* Only show provider name if MULTI_PROVIDER. Ticket [here](https://app.shortcut.com/updater/story/105441/add-provider-name-to-lead-mode-shopping-cart-for-multi-provider-configs) */}
            {shopType === ShopType.MULTI_PROVIDER && `${providerName} `}
            {sessionStorageValue?.offer?.planName}
          </Box>

          {displayPrice && (
            <Box my="xs">
              <Price
                variant="mBold"
                amount={displayPrice.amount}
                term={displayPrice.term === 'month' ? 'mo' : displayPrice.term}
                termColor={Palette.gray40}
                mt="xs"
                mb="xs"
              />
            </Box>
          )}
        </Flex>
        {showDisclaimer && (
          <Text data-testid="pricing-description" as="p" variant="m" pb="xs">
            {sessionStorageValue?.offer?.pricingDescription}.
          </Text>
        )}
      </Box>
    </Box>
  );
};
